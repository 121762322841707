import React from "react";
import Header from "./Header";
import Footer from "./Footer";
import Sidebar from "./Sidebar";

export default function AdminLayout({ children }) {
  return (
    <div className="flex">
      <Sidebar />
      <div className="w-full flex flex-col justify-between min-h-[100svh]">
        <div className="">
          <Header />
          <div className="p-5">{children}</div>
        </div>
        <div className="">
          <Footer />
        </div>
      </div>
    </div>
  );
}
