import { combineReducers } from "redux";
import infoReducer from "../info/store/reducer";
import adminReducer from "../admin/store/reducer";
import memberReducer from "../member/store/reducer";

const rootReducer = combineReducers({
  info: infoReducer,
  admin: adminReducer,
  member: memberReducer,
});

export default rootReducer;
