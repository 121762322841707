import React from "react";
import { useSelector } from "react-redux";

export default function Header() {
  return (
    <div className="w-full flex flex-col items-start justify-center px-10 min-h-[100px] bg-green-300">
      <p className="text-xl md:text-2xl lg:text-4xl text-gray-700 font-bold">
        Welcome to Wadil-Aqeeq Report
      </p>
    </div>
  );
}
