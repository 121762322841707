import classnames from "classnames";
import { useNavigate } from "react-router-dom";

export const Content = ({
  width,
  title,
  content,
  titleClass = "text-gray-800",
  contentClass = "text-gray-600",
}) => {
  return (
    <div className={classnames("w-full my-1", width)}>
      <p className={classnames("text-xs xl:text-sm", titleClass)}>{title}</p>
      <p
        className={classnames(
          "text-base md:text-sm xl:text-xl font-medium",
          contentClass
        )}
      >
        {content}
      </p>
    </div>
  );
};

export const HeaderAdmin = ({ title }) => {
  return (
    <div className="">
      <p className="text-2xl font-medium">{title}</p>
    </div>
  );
};

export const ButtonLinkAdmin = ({
  children,
  href,
  className = "bg-yellow-500 hover:bg-yellow-100 text-white hover:text-yellow-500 ",
  blank = false,
}) => {
  const navigate = useNavigate();

  const handleNavigation = () => {
    navigate(href);
  };

  return (
    <div>
      <div
        onClick={handleNavigation}
        target={blank ? "_blank" : "_self"}
        className={classnames(
          "duration-300 h-10 w-fit px-2 md:px-2 xl:px-3 rounded-md text-base md:text-sm lg:text-base flex items-center cursor-pointer",
          className //"bg-yellow-500 hover:bg-yellow-100 text-white hover:text-yellow-500 "
        )}
      >
        {children}
      </div>
    </div>
  );
};

export const ButtonAdmin = ({
  children,
  onClick,
  className = "bg-red-500 hover:bg-red-100 text-white hover:text-red-500",
  disable = false,
}) => {
  return (
    <button
      onClick={onClick}
      className={classnames(
        "duration-300 h-10 px-2 xl:px-5 rounded-md text-base md:text-sm lg:text-base xl:text-base",
        className //"bg-red-500 hover:bg-red-100 text-white hover:text-red-500"
      )}
      disabled={disable}
    >
      {children}
    </button>
  );
};

export const ButtonGoBack = () => {
  const navigate = useNavigate();
  const goBack = () => {
    navigate(-1);
  };
  return (
    <ButtonAdmin
      onClick={goBack}
      className="bg-red-500 hover:bg-red-100 text-white hover:text-red-500 w-32"
    >
      กลับ
    </ButtonAdmin>
  );
};
