import React, { Suspense, lazy } from "react";
import { Route } from "react-router-dom";
import AdminLayout from "./AdminLayout";

export const routes = [
  {
    path: "/adminLogin",
    exact: true,
    layout: "",
    component: lazy(() => import("../pages/Login/Login")),
  },

  {
    path: "/admin/home",
    exact: true,
    layout: "admin",
    component: lazy(() => import("../pages/Home/Home")),
  },

  {
    path: "/admin/usermember",
    exact: true,
    layout: "admin",
    component: lazy(() => import("../pages/memUsers/memUserLs")),
  },
  {
    path: "/admin/manageusermember/:type/:username",
    exact: true,
    layout: "admin",
    component: lazy(() => import("../pages/memUsers/ManageUserMember")),
  },

  {
    path: "/admin/memberinfo",
    exact: true,
    layout: "admin",
    component: lazy(() => import("../pages/memInfo/MemInfo")),
  },
  {
    path: "/admin/managemember/:type/:mem_id",
    exact: true,
    layout: "admin",
    component: lazy(() => import("../pages/memInfo/ManageMember")),
  },

  {
    path: "/admin/activity",
    exact: true,
    layout: "admin",
    component: lazy(() => import("../pages/memActivity/MemActivity")),
  },
  {
    path: "/admin/manageactivity/:type/:code",
    exact: true,
    layout: "admin",
    component: lazy(() => import("../pages/memActivity/ManageActivity")),
  },

  {
    path: "/admin/auditwork",
    exact: true,
    layout: "",
    component: lazy(() => import("../pages/AuditWork/AuditWork")),
  },

  {
    path: "/admin/useradmin",
    exact: true,
    layout: "admin",
    component: lazy(() => import("../pages/AdminUsers/AdminUserLs")),
  },
  {
    path: "/admin/manageuseradmin/:type/:username",
    exact: true,
    layout: "admin",
    component: lazy(() => import("../pages/AdminUsers/ManageUserAdmin")),
  },
];

export default function AdminRoutes({ component: Component, ...rest }) {
  return (
    <Suspense fallback={<></>}>
      <AdminLayout>
        <Component {...rest} />
      </AdminLayout>
    </Suspense>
  );
}
