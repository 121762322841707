import Swal from "sweetalert2";
import "./AlertService.css";

export const Toast = Swal.mixin({
  toast: true,
  position: "top-end",
  iconColor: "white",
  customClass: {
    popup: "colored-toast",
  },
  showConfirmButton: false,
  timer: 2000,
  timerProgressBar: true,
});

export function alertSuccess(text = "สำเร็จ") {
  return Swal.fire({
    position: "center",
    icon: "success",
    title:
      '<p style=" color:#47C460; font-size: 20px;  font-weight: 500;">' +
      text +
      "</p>",
    showConfirmButton: false,
    timer: 1500,
  });
}

export function alertError(textTitle = "ผิดผลาด", textContent = "") {
  return Swal.fire({
    position: "center",
    icon: "error",
    title:
      '<p style=" color: #dd6b55; font-size: 20px;  font-weight: 500;">' +
      textTitle +
      "</p>",
    text: textContent,
    showConfirmButton: true,
    confirmButtonText: "ตกลง",
    confirmButtonColor: "#dd6b55",
  });
}

export function alertWarning(textTitle, textContent = "") {
  Swal.fire({
    position: "center",
    icon: "warning",
    title:
      '<p style=" color: #E59840; font-size: 20px;  font-weight: 500;">' +
      textTitle +
      "</p>",
    text: textContent,
    showConfirmButton: true,
    confirmButtonText: "ตกลง",
  });
}

export function alertConfirm({ html, icon }) {
  const swalOptions = {
    html: html,
    showCancelButton: true,
    confirmButtonText: "ตกลง",
    cancelButtonText: "ยกเลิก",
    confirmButtonColor: "#47C460",
    cancelButtonColor: "#dd6b55",
  };

  if (icon) {
    swalOptions.icon = icon;
  }

  return Swal.fire(swalOptions);
}
