import React, { Suspense } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import routes from "./routes";
import InfoRoutes from "../info/layouts/InfoRoutes";
import NoMatch from "../pages/NoMatch/NoMatch";
import AdminRoutes from "../admin/layouts/AdminRoutes";
import MemberRoutes from "../member/layouts/MemberRoutes";

export default function AppRoutes() {
  return (
    <div>
      <Router>
        <Routes>
          {routes.map((route, i) => {
            if (route.layout === "info") {
              return (
                <Route
                  key={i}
                  path={route.path}
                  element={<InfoRoutes {...route} />}
                />
              );
            } else if (route.layout === "admin") {
              return (
                <Route
                  key={i}
                  path={route.path}
                  element={<AdminRoutes {...route} />}
                />
              );
            } else if (route.layout === "member") {
              return (
                <Route
                  key={i}
                  path={route.path}
                  element={<MemberRoutes {...route} />}
                />
              );
            } else {
              return (
                <Route
                  key={i}
                  path={route.path}
                  element={
                    <Suspense fallback={<div>Loading...</div>}>
                      <route.component />
                    </Suspense>
                  }
                />
              );
            }
          })}
          <Route path={"*"} element={<NoMatch />} />
        </Routes>
      </Router>
    </div>
  );
}
