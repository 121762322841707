import { SET_NAME, SET_AUTHENTICATION, SET_COUNT } from "./actionTypes";

const initialState = {
  count: 0,
};

const infoReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_NAME:
      return {
        ...state,
        name: action.payload,
      };
    case SET_AUTHENTICATION:
      return {
        ...state,
        authentication: action.payload,
      };
    case SET_COUNT:
      return {
        ...state,
        count: action.payload,
      };
    default:
      return state;
  }
};

export default infoReducer;
