import { faRightFromBracket } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { useNavigate } from "react-router-dom";
import { alertConfirm } from "../../services/alertService";
import { useSelector } from "react-redux";

export default function Header() {
  const navigate = useNavigate();
  const pathname = window.location.pathname;

  const handleNavigation = (link) => {
    navigate(link);
  };

  const { authentication } = useSelector((state) => state.member);

  const logOut = () => {
    alertConfirm({
      html: "<p class='text-4xl'>ต้องการออกจากระบบ</p>",
      icon: "question",
    }).then((val) => {
      if (val.isConfirmed) {
        localStorage.removeItem("access_token_member");
        localStorage.removeItem("member");
        window.location.href = "/";
      }
    });
  };

  return (
    <div className="w-full flex flex-col items-center py-5 bg-purple-200">
      <div className="">
        <div className="flex flex-col md:flex-row gap-x-1">
          <p className="text-2xl">อัสลามมูอาลัยกุม</p>
          <div className="text-2xl">คุณ {authentication.member.name}</div>
        </div>
        <p className="text-xl">รหัสสมาชิก {authentication.member.mem_id}</p>
      </div>
      <div className="mt-5 w-full flex justify-center gap-5">
        {[
          { name: "หน้าแรก", link: "/member/home" },
          { name: "กิจกรรม", link: "/member/activity" },
          { name: "เปลี่ยนรหัสผ่าน", link: "/member/resetpass" },
        ].map((value, index) => {
          return (
            <div
              key={index}
              onClick={() => handleNavigation(value.link)}
              className={`w-fit cursor-pointer hover:font-semibold ${
                pathname == value.link ? "font-semibold" : ""
              }`}
            >
              <p className="w-fit underline text-lg">{value.name}</p>
            </div>
          );
        })}
      </div>
      <div
        className="absolute w-12 h-12 rounded-xl bg-red-500 text-white flex justify-center items-center right-3 top-3 md:right-5 md:top-5 cursor-pointer"
        onClick={logOut}
      >
        <FontAwesomeIcon icon={faRightFromBracket} />
      </div>
    </div>
  );
}
