import React, { Suspense, lazy } from "react";
// import { Route } from "react-router-dom";
import MemberLayout from "./MemberLayout";

export const routes = [
  {
    path: "/member/home",
    exact: true,
    layout: "member",
    component: lazy(() => import("../pages/Home/Home")),
  },

  {
    path: "/memberlogin",
    exact: true,
    layout: "",
    component: lazy(() => import("../pages/Login/Login")),
  },
  {
    path: "/member/resetpass",
    exact: true,
    layout: "member",
    component: lazy(() => import("../pages/Login/ResetPass")),
  },

  {
    path: "/member/activity",
    exact: true,
    layout: "member",
    component: lazy(() => import("../pages/Activity/Activity")),
  },
  {
    path: "/member/activityform/:code/:type/:seq",
    exact: true,
    layout: "member",
    component: lazy(() => import("../pages/Activity/ActivityForm")),
  },
];

export default function MemberRoutes({ component: Component, ...rest }) {
  return (
    <Suspense fallback={<></>}>
      <MemberLayout>
        <Component {...rest} />
      </MemberLayout>
    </Suspense>
  );
}
