import React from "react";
import Header from "./Header";
import Footer from "./Footer";

export default function MemberLayout({ children }) {
  return (
    <div className="min-h-[100svh] flex flex-col justify-between">
      <div className="">
        <div className="min-h-[5svh]">
          <Header />
        </div>
        <div className="p-5">{children}</div>
      </div>
      <div className="min-h-[5svh]">
        <Footer />
      </div>
    </div>
  );
}
