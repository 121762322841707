import React from "react";
import Header from "./Header";
import Footer from "./Footer";

export default function InfoLayout({ children }) {
  return (
    <div className="min-h-[100svh] flex flex-col justify-between">
      {/* <div className="min-h-[5svh]">
        <Header />
      </div> */}
      <div className="p-5">{children}</div>
      <div className="min-h-[5svh] flex justify-center items-center">
        <Footer />
      </div>
    </div>
  );
}
