import React from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { ButtonAdmin } from "../components/components";
import { alertConfirm } from "../../services/alertService";

export default function Sidebar() {
  const navigate = useNavigate();

  const {
    authentication,
    currentSidebar,
    // isOpenMaster
  } = useSelector((state) => state.admin);

  const menuLink = [
    { name: "หน้าแรก", link: "/admin/home", active: 1 },
    { name: "ข้อมูลสมาชิก", link: "/admin/memberinfo", active: 3 },
    { name: "users สมาชิก", link: "/admin/usermember", active: 2 },
    { name: "กิจกรรม", link: "/admin/activity", active: 4 },
    { name: "users แอดมิน", link: "/admin/useradmin", active: 5 },
  ];

  const handleNavigation = (link) => {
    navigate(link);
  };

  const logOut = () => {
    alertConfirm({
      html: "<p class='text-4xl'>ต้องการออกจากระบบ</p>",
      icon: "question",
    }).then((val) => {
      if (val.isConfirmed) {
        localStorage.removeItem("access_token");
        localStorage.removeItem("admin");
        window.location.href = "/";
      }
    });
  };

  return (
    <div className="min-w-[200px] overflow-hidden">
      <div className="fixed min-w-[200px] px-5 pt-14 pb-5 flex flex-col justify-between min-h-[100svh] bg-green-50">
        <div className="">
          {authentication.admin.admin_name && (
            <div className="pb-3 border-b-2">
              <p className="text-base md:text-base lg:text-xl text-gray-700 font-semibold">
                คุณ {authentication.admin.admin_name}
              </p>
            </div>
          )}
          <ul className={`flex flex-col gap-y-2 mt-5`}>
            {menuLink.map((val, i) => {
              return (
                <div
                  key={i}
                  onClick={() => handleNavigation(val.link)}
                  className={`
                  text-xl font-normal py-2 px-3 border-r-4 border-transparent
                  ${
                    currentSidebar == val.active
                      ? "bg-gray-400 text-white rounded-xl"
                      : "text-gray-700"
                  }
                  hover:border-gray-400 hover:font-semibold cursor-pointer line-clamp-1 duration-300 
                  `}
                >
                  {val.name}
                </div>
              );
            })}
          </ul>
        </div>
        <div className="">
          <ButtonAdmin
            onClick={logOut}
            className="bg-red-500 hover:bg-red-100 text-white hover:text-red-500 w-full"
          >
            Logout
          </ButtonAdmin>
        </div>
      </div>
    </div>
  );
}
