import React, { Suspense, lazy } from "react";
import { Route } from "react-router-dom";
import InfoLayout from "./InfoLayout";

export const routes = [
  {
    path: "/",
    exact: true,
    layout: "info",
    component: lazy(() => import("../pages/Home/Home")),
  },
];

export default function InfoRoutes({ component: Component, ...rest }) {
  return (
    <Suspense fallback={<></>}>
      <InfoLayout>
        <Component {...rest} />
      </InfoLayout>
    </Suspense>
  );
}
