import { SET_NAME, SET_AUTHENTICATION, SET_COUNT } from "./actionTypes";

const member = JSON.parse(localStorage.getItem("member"));
const initialState = {
  count: 0,
  authentication: member
    ? { login: true, member: { ...member } }
    : { login: false, member: [] },
};

const memberReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_NAME:
      return {
        ...state,
        name: action.payload,
      };
    case SET_AUTHENTICATION:
      return {
        ...state,
        authentication: action.payload,
      };
    case SET_COUNT:
      return {
        ...state,
        count: action.payload,
      };
    default:
      return state;
  }
};

export default memberReducer;
