import {
  SET_NAME,
  SET_AUTHENTICATION,
  SET_CURRENT_SIDEBAR,
} from "./actionTypes";

const admin = JSON.parse(localStorage.getItem("admin"));

const initialState = {
  count: 0,
  authentication: admin
    ? { login: true, admin: { ...admin } }
    : { login: false, admin: [] },
};

const adminReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_NAME:
      return {
        ...state,
        name: action.payload,
      };
    case SET_AUTHENTICATION:
      return {
        ...state,
        authentication: action.payload,
      };
    case SET_CURRENT_SIDEBAR:
      return {
        ...state,
        currentSidebar: action.payload,
      };
    default:
      return state;
  }
};

export default adminReducer;
